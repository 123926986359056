import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';


const CustomNavLink = ({ to, state, children }) => {
  return (
    <Link to={to} state={state}>
      {children}
    </Link>
  );
};

export const ComingSoon = () => {
  return (
    <Container fluid style={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Row className="justify-content-center" style={{ width: '100%', marginLeft: '60px', marginRight: '60px' }}>
        <Col md="12">
          <Card style={{ width: '100%' }}>
            <CardBody style={{ textAlign: 'left', padding: '40px' }}>
              <div style={{ marginBottom: '20px', fontSize: '28px', fontFamily: 'Montserrat' }}>Coming Soon...</div>
              <div style={{ marginBottom: '40px', fontSize: '22px', fontFamily: 'Montserrat' }}>
                This feature is currently in development. Please check one of these existing pages:
              </div>
              <div style={{ display: 'flex', justifyContent: 'left', gap: '15px' }}>
                <CustomNavLink to="/admin/explore-reports" state={{ country: ['Ukraine', 'Russia'] }}>
                  <button className="btn btn-info">Ukraine / Russia</button>
                </CustomNavLink>
                <CustomNavLink to="/admin/explore-reports" state={{ country: ['Israel', 'Palestine'] }}>
                  <button className="btn btn-info">Israel / Palestine</button>
                </CustomNavLink>
                <CustomNavLink to="/admin/explore-reports" state={{ reportType: ['UXOs'] }}>
                  <button className="btn btn-info">UXOs</button>
                </CustomNavLink>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
