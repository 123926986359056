import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

function InvestigationPage() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/admin/dashboard'); 
  };

  return (
    <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', backgroundColor: '#f4f4f4' }}>
      <div style={{ width: '100%', marginRight: '50px',marginLeft: '50px' }}>
        <button
          className='btn btn-info'
          onClick={handleBackClick}
          style={{ marginBottom: '10px', marginTop: '50px' }}
        >
          Go Back
        </button>
        <Card style={{ width: '100%', padding: '20px', backgroundColor: 'white', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
          <CardBody style={{ fontSize: '22px', color: '#333', lineHeight: '1.5' }}>
            <p  style={{marginTop: '20px' }}>
              Investigations are crucial because threats can arise unexpectedly from various sources, making it difficult to stay informed and prepared using traditional security measures alone. Sleuth Net addresses this issue by utilizing crowd-sourced information to provide real-time threat intelligence. By aggregating data from a global network of users and combining it with both public and private datasets, Sleuth Net delivers immediate and actionable insights to help individuals and communities protect themselves and their loved ones.
            </p>
            <p>
              The importance of investigations is highlighted through the key advantages offered by Sleuth Net:
            </p>
            <ul>
              <li>Real-Time Hazard Awareness: The platform’s mobile application, Sentinel, integrates real-time threat reporting with an analytical dashboard, enabling near-instant awareness of potential hazards.</li>
              <li>Community-Driven Data: A wide network of users contributes diverse and comprehensive threat coverage, making the data more robust and inclusive.</li>
              <li>Reliable Information: Sleuth Net employs a rigorous verification process to ensure that the threat data it provides is accurate and trustworthy, coming from verified sources.</li>
              <li>User-Friendly Interface: The intuitive design allows users to easily navigate through information and alerts, making threat awareness accessible to everyone.</li>
            </ul>
            <p>
              Sleuth Net currently tracks a variety of threats, including unexploded ordnance, military activity, war crimes, criminal activity, extreme weather events, hazardous wildlife, structure hazards, and infrastructure issues. It also monitors conflict areas like Ukraine/Russia, Israel/Palestine, and violent extremist organizations. Future tracking efforts will expand to cover issues such as human trafficking, migrant crises, cyber attacks, and climate change, further emphasizing the importance of ongoing investigations to stay ahead of emerging threats.
            </p>
            <p>
              Overall, investigations are vital for maintaining safety and preparedness, enabling proactive responses to both immediate and evolving dangers.
            </p>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default InvestigationPage;
