import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, Input, Label, Row, Col, CardSubtitle } from 'reactstrap';
import { VectorMap as JVectorMap } from 'react-jvectormap';

const reportTypes = [
  { type: 'uxo', color: '#FF2C2C', label: 'UXOs' },
  { type: 'militaryActivity', color: '#04c4bc', label: 'Military Activity' },
  { type: 'wildlife', color: '#a2845e', label: 'Wildlife' },
  { type: 'warCrime', color: '#5454d4', label: 'War Crimes' },
  { type: 'criminalActivity', color: '#fc9404', label: 'Criminal Activity' },
  { type: 'resourceScarcity', color: '#40c878', label: 'Resource Scarcity' },
  { type: 'structureHazard', color: '#ffcc00', label: 'Structure Hazard' },
  { type: 'naturalDisaster', color: '#047cfc', label: 'Natural Disaster' },
  { type: 'terroristActivity', color: '#000000', label: 'Terrorist Activity' },
  { type: 'nasa', color: '#32ace5', label: 'NASA EONet Events' },
  { type: 'publicHealthHazard', color: '#C05780', label: 'Public Health Hazard' },
  { type: 'cyberThreat',color: '#6169ae',  label: 'Cyber Threat' },
  { type: 'transportationHazard', color: '#ff8d85',  label: 'Transportation Hazard' },
  { type: 'environmentalHazard', color: '#afb838',label: 'Environmental Hazard' },
  { type: 'humanTrafficking', color: '#83928b', label: 'Human Trafficking' }
];

const reportNameMapping = {
  uxo: 'UXO',
  militaryActivity: 'Military Activity',
  naturalDisaster: 'Natural Disaster',
  structureHazard: 'Structure Hazard',
  terroristActivity: 'Terrorist Activity',
  criminalActivity: 'Criminal Activity',
  warCrime: 'War Crimes',
  wildlife: 'Wildlife Hazard',
  resourceScarcity: 'Resource Scarcity',
  nasa: 'NASA EONet Events',
  publicHealthHazard: 'Public Health Hazard',
  cyberThreat: 'Cyber Threat',
  transportationHazard: 'Transportation Hazard',
  environmentalHazard: 'Environmental Hazard',
  humanTrafficking: 'Human Trafficking'
};

const ExploreMapMain = ({ reports = [] }) => {
  const [selectedReportTypes, setSelectedReportTypes] = useState(reportTypes.map((rt) => rt.type));

  const handleCheckboxChange = (type) => {
    const updatedTypes = selectedReportTypes.includes(type)
      ? selectedReportTypes.filter((t) => t !== type)
      : [...selectedReportTypes, type];
    setSelectedReportTypes(updatedTypes);
  };

  const handleSelectAll = () => {
    setSelectedReportTypes(
      selectedReportTypes.length === reportTypes.length ? [] : reportTypes.map((rt) => rt.type)
    );
  };

  const markers = reports
    .filter((report) => selectedReportTypes.includes(report.reportType) && report.latitude && report.longitude)
    .map((report) => ({
      latLng: [report.latitude, report.longitude],
      name: `Report: ${reportNameMapping[report.reportType] || report.reportType}`,
      style: { fill: reportTypes.find((rt) => rt.type === report.reportType)?.color || '#000' },
      id: report.reportId,
    }));

  const reportTypeItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    padding: '3px 0px',
  };

  const circleStyle = (color) => ({
    backgroundColor: color,
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    marginRight: '8px'
  });

  const checkboxStyle = (hasReports) => ({
    marginLeft: '-4px',
    cursor: hasReports ? 'pointer' : 'not-allowed',
    opacity: hasReports ? 1 : 0.5,
  });

  return (
    <Card className="map" style={{ overflow: 'visible', height: 'auto' }}>
      <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
        <Row>
          <Col md="4">
            <CardTitle tag="h5">Explore the Map</CardTitle>
            <CardSubtitle style={{ marginBottom: '10px', display: 'block' }}>Topic Filter</CardSubtitle>
            <div style={{ display: 'flex', marginLeft: '40px', marginBottom: '10px',  marginTop: '20px' }}>
              <Label style={{ color: 'black', whiteSpace: 'nowrap' }}>Select All</Label>
              <div style={{ marginLeft: 'auto'}}>
                <Input type="checkbox" onChange={handleSelectAll} checked={selectedReportTypes.length === reportTypes.length} />
              </div>
            </div>
            <Col style={{ overflowY: 'scroll', maxHeight: '380px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {reportTypes.map((reportType) => {
                  const hasReports = reports.some((report) => report.reportType === reportType.type);
                  return (
                    <div key={reportType.type} style={reportTypeItemStyle}>
                      <div style={{ display: 'flex' }}>
                        <div className="report-type-color" style={circleStyle(reportType.color)}></div>
                        <Label style={{ color: hasReports ? 'black' : 'gray' }}>{reportType.label}</Label>
                      </div>
                      <div>
                        <Input
                          type="checkbox"
                          onChange={() => handleCheckboxChange(reportType.type)}
                          checked={selectedReportTypes.includes(reportType.type)}
                          disabled={!hasReports}
                          style={checkboxStyle(hasReports)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Col>

          {/* Map Section */}
          <Col md="8">
            <div style={{ marginTop: '40px', width: '100%', height: '100%' }}>
              <JVectorMap
                map="world_mill"
                backgroundColor="#E0E0E0"
                containerStyle={{
                  width: '100%',
                  height: '400px',
                }}
                markers={selectedReportTypes.length > 0 ? markers : []}
                markerStyle={{
                  initial: {
                    fill: '#FF0000',
                    stroke: '#000',
                    "stroke-width": 0.3,
                    r: 6,
                  },
                  hover: {
                    stroke: '#000',
                    "stroke-width": 2,
                    cursor: 'pointer',
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </CardBody>
      <p style={{ fontSize: '10px', marginLeft: '20px', marginTop: '20px' }}>
        If the checkboxes are grayed out, there are no reports.
      </p>
    </Card>
  );
};

export default ExploreMapMain;
