export const geographicRegions = {
    'North America': [
      'United States', 'Canada', 'Mexico', 'Greenland', 'Bermuda', 
      'Saint Pierre and Miquelon', 'Bahamas', 'Barbados', 'Cuba', 'Dominican Republic', 
      'Haiti', 'Jamaica', 'Puerto Rico', 'Saint Kitts and Nevis', 'Saint Lucia',
      'Saint Vincent and the Grenadines', 'Trinidad and Tobago', 'Antigua and Barbuda', 
      'Belize', 'Costa Rica', 'El Salvador', 'Guatemala', 'Honduras', 'Nicaragua', 
      'Panama'
    ],
    'South America': [
      'Argentina', 'Bolivia', 'Brazil', 'Chile', 'Colombia', 'Ecuador', 
      'Guyana', 'Paraguay', 'Peru', 'Suriname', 'Uruguay', 'Venezuela', 
      'French Guiana', 'Falkland Islands'
    ],
    'Asia/Pacific': [
      'Australia', 'New Zealand', 'Fiji', 'Papua New Guinea', 'Solomon Islands',
      'Vanuatu', 'Samoa', 'Tonga', 'Kiribati', 'Micronesia', 'Palau',
      'Marshall Islands', 'Nauru', 'Tuvalu', 'China', 'India', 'Indonesia', 
      'Japan', 'South Korea', 'North Korea', 'Malaysia', 'Singapore', 'Thailand', 
      'Vietnam', 'Philippines', 'Myanmar (Burma)', 'Cambodia', 'Laos', 'Brunei',
      'East Timor', 'Bangladesh', 'Bhutan', 'Maldives', 'Nepal', 'Sri Lanka', 
      'Mongolia', 'Taiwan', 'Hong Kong', 'Macau'
    ],
    'Europe': [
      'United Kingdom', 'Germany', 'France', 'Italy', 'Spain', 'Portugal', 
      'Netherlands', 'Belgium', 'Luxembourg', 'Ireland', 'Switzerland', 
      'Austria', 'Poland', 'Czech Republic', 'Slovakia', 'Hungary', 'Romania', 
      'Bulgaria', 'Greece', 'Turkey', 'Norway', 'Sweden', 'Finland', 'Denmark',
      'Iceland', 'Estonia', 'Latvia', 'Lithuania', 'Belarus', 'Ukraine', 'Russia', 
      'Georgia', 'Armenia', 'Azerbaijan', 'Serbia', 'Montenegro', 'Bosnia and Herzegovina',
      'Croatia', 'Slovenia', 'North Macedonia', 'Albania', 'Kosovo', 'Moldova', 
      'Cyprus', 'Malta', 'Andorra', 'Monaco', 'Liechtenstein', 'San Marino', 
      'Vatican City'
    ],
    'Africa': [
      'Nigeria', 'South Africa', 'Kenya', 'Ghana', 'Ethiopia', 'Uganda', 
      'Tanzania', 'Rwanda', 'Burundi', 'Sudan', 'South Sudan', 'Somalia',
      'Chad', 'Niger', 'Mali', 'Mauritania', 'Senegal', 'Gambia', 'Guinea', 
      'Sierra Leone', 'Liberia', 'Ivory Coast', 'Burkina Faso', 'Togo', 'Benin', 
      'Cameroon', 'Central African Republic', 'Gabon', 'Congo (Brazzaville)', 
      'Congo (Kinshasa)', 'Equatorial Guinea', 'Angola', 'Zambia', 'Malawi',
      'Mozambique', 'Zimbabwe', 'Botswana', 'Namibia', 'Lesotho', 'Eswatini',
      'Madagascar', 'Mauritius', 'Seychelles', 'Cape Verde', 'Comoros', 
      'Djibouti', 'Eritrea', 'Libya', 'Tunisia', 'Algeria', 'Morocco', 'Western Sahara',
      'Egypt', 'Sudan', 'South Sudan'
    ],
    'Middle East': [
      'Saudi Arabia', 'Israel', 'United Arab Emirates', 'Jordan', 'Lebanon', 
      'Syria', 'Iraq', 'Kuwait', 'Bahrain', 'Qatar', 'Oman', 'Yemen', 
      'Turkey', 'Iran', 'Palestine', 'Armenia', 'Azerbaijan', 'Georgia',
      'Cyprus'
    ]
  };
  