export const categories = [
  {
    id: 1,
    name: 'Antipersonnel-Mines',
    items: [
      { id: 1, name: 'antipersonnel_mine_1' },
      { id: 2, name: 'antipersonnel_mine_2' },
      { id: 3, name: 'antipersonnel_mine_3' },
      { id: 4, name: 'antipersonnel_mine_4' },
      { id: 5, name: 'antipersonnel_mine_5' },
      { id: 6, name: 'antipersonnel_mine_6' },
    ],
  },
  {
    id: 2,
    name: 'Antitank-Mines',
    items: [
      { id: 1, name: 'antitank_mine_1' },
      { id: 2, name: 'antitank_mine_2' },
      { id: 3, name: 'antitank_mine_3' },
      { id: 4, name: 'antitank_mine_4' },
    ],
  },
  {
    id: 3,
    name: 'Dropped-Dispensers',
    items: [
      { id: 1, name: 'dropped_dispenser_1' },
      { id: 2, name: 'dropped_dispenser_2' },
      { id: 3, name: 'dropped_dispenser_4' },
      { id: 4, name: 'dropped_dispenser_5' },
      { id: 5, name: 'dropped_dispenser_6' },
      { id: 6, name: 'dropped_dispenser_7' },
    ],
  },
  {
    id: 4,
    name: 'Dropped-Submunition',
    items: [
      { id: 1, name: 'dropped_submunition_1'},
      { id: 2, name: 'dropped_submunition_2'},
      { id: 3, name: 'dropped_submunition_3'},
      { id: 4, name: 'dropped_submunition_4'},
      { id: 5, name: 'dropped_submunition_5'},
      { id: 6, name: 'dropped_submunition_6'},
    ],
  },
  {
    id: 5,
    name: 'General-Purpose-Bomb-Chemical',
    items: [
      { id: 1, name: 'general_purpose_bomb_chemical_1' },
      { id: 2, name: 'general_purpose_bomb_chemical_2' },
    ],
  },
  {
    id: 6,
    name: 'Gerneral-Purpose-Bombs',
    items: [
      { id: 1, name: 'general_purpose_bomb_1' },
      { id: 2, name: 'general_purpose_bomb_2' },
      { id: 3, name: 'general_purpose_bomb_3' },
      { id: 4, name: 'general_purpose_bomb_4' },
      { id: 5, name: 'general_purpose_bomb_5' },
      { id: 6, name: 'general_purpose_bomb_6' },
      { id: 7, name: 'general_purpose_bomb_7' },
      { id: 8, name: 'general_purpose_bomb_8' },
    ],
  },
  {
    id: 7,
    name: 'Missiles',
    items: [
      { id: 1, name: 'missile_1' },
      { id: 2, name: 'missile_2' },
    ],
  },
  {
    id: 8,
    name: 'Mortars',
    items: [
      { id: 1, name: 'mortar_1'},
      { id: 2, name: 'mortar_2'},
      { id: 3, name: 'mortar_3'},
      { id: 4, name: 'mortar_4'},
    ],
  },
  {
    id: 9,
    name: 'Projectiles',
    items: [
      { id: 1, name: 'projectile_1'},
      { id: 2, name: 'projectile_2'},
      { id: 3, name: 'projectile_3'},
      { id: 4, name: 'projectile_4'},
      { id: 5, name: 'projectile_5'},
      { id: 6, name: 'projectile_6'},
      { id: 7, name: 'projectile_7'},
      { id: 8, name: 'projectile_8'},
      { id: 9, name: 'projectile_9'},
      { id: 10, name: 'projectile_10' },
      { id: 11, name: 'projectile_11' },
      { id: 12, name: 'projectile_12' },
      { id: 13, name: 'projectile_13' },
      { id: 14, name: 'projectile_14' },
      { id: 15, name: 'projectile_15' },
      { id: 16, name: 'projectile_16' },
    ],
  },
  {
    id: 10,
    name: 'Rifle-Grenades',
    items: [
      { id: 1, name: 'rifle_grenade_1' },
      { id: 2, name: 'rifle_grenade_2' },
      { id: 3, name: 'rifle_grenade_3' },
      { id: 4, name: 'rifle_grenade_4' },
    ],
  },
  {
    id: 11,
    name: 'Rockets',
    items: [
      { id: 1, name: 'rocket_1' },
      { id: 2, name: 'rocket_2' },
    ],
  },
  {
    id: 12,
    name: 'Thrown-Grenades',
    items: [
      { id: 1, name: 'grenade_1' },
      { id: 2, name: 'grenade_2' },
      { id: 3, name: 'grenade_3' },
      { id: 4, name: 'grenade_4' },
      { id: 5, name: 'grenade_5' },
      { id: 6, name: 'grenade_6' },
      { id: 7, name: 'grenade_7' },
      { id: 8, name: 'grenade_8' },
      { id: 9, name: 'grenade_9' },
      { id: 10, name: 'grenade_10'},
    ],
  },
];

export const mapTypeToBombName = (bombType) => {
  for (const category of categories) {
    const item = category.items.find(item => item.name === bombType);
    if (item) {
      return category.name; 
    }
  }
  return "Unknown";
};
