import React, { useState, useEffect } from "react";
import classnames from 'classnames'; 
import {
  Collapse,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Navbar,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../routes"; 

function AdminNavbar(props) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [color, setColor] = useState("navbar-transparent");
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const updateColor = () => {
      if (window.innerWidth < 993 && collapseOpen) {
        setColor("bg-white");
      } else {
        setColor("navbar-transparent");
      }
    };

    window.addEventListener("resize", updateColor);
    return () => window.removeEventListener("resize", updateColor);
  }, [collapseOpen]);

  useEffect(() => {
    if (window.outerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }, [location]);

 
  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    if (input.length > 0) {
      const filteredRoutes = routes.filter(route => route.name.toLowerCase().includes(input.toLowerCase()));
      setSearchResults(filteredRoutes);
      setDropdownOpen(true);
    } else {
      setSearchResults([]);
      setDropdownOpen(false);
    }
  };

  const handleSelectRoute = (path) => {
    navigate(path);
    setSearchInput("");
    setSearchResults([]);
    setDropdownOpen(false);
  };

  const getTitle = () => {
    const currentPath = location.pathname;
    const route = routes.find(route => {
      const path = route.layout + route.path;
      const regex = new RegExp(`^${path.replace(/:\w+/g, '[^/]+')}$`);
      return regex.test(currentPath);
    });
    return route ? route.name : "Dashboard";
  };
  
  return (
    <Navbar className={classnames("navbar-absolute fixed-top", color)} expand="sm">
      <Container fluid>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div 
            style={{ 
              width: '20px', 
              height: '20px', 
              borderRadius: '50%', 
              backgroundColor: '#66615B', 
              marginRight: '10px' 
            }} 
          />
          <div className="navbar-brand" style={{ fontSize: '20px', color: '#66615B' }}>
            {getTitle()}
          </div>
        </div>
        <Collapse className="justify-content-end" navbar isOpen={collapseOpen}>
          {/* <Form inline onSubmit={(e) => e.preventDefault()}>
            <InputGroup className="no-border">
              <Input value={searchInput} onChange={handleSearchChange} placeholder="Search..." type="text" />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="nc-icon nc-zoom-split" />
                </InputGroupText>
              </InputGroupAddon>
              <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                <DropdownToggle 
                  tag="span" 
                  data-toggle="dropdown" 
                  aria-expanded={dropdownOpen}
                  style={{ opacity: 0, position: 'absolute', cursor: "pointer" }}
                />
                <DropdownMenu right style={{ position: 'absolute', top: "100%", marginTop: '50px' }}>
                  {searchResults.length > 0 ? (
                    searchResults.map((result, index) => (
                      <DropdownItem key={index} onClick={() => handleSelectRoute(result.layout + result.path)}>
                        {result.name}
                      </DropdownItem>
                    ))
                  ) : (
                    <DropdownItem disabled>No matches found</DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </InputGroup>
          </Form> */}
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default AdminNavbar;
