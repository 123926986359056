import React, { useState, useEffect } from 'react';
import { Table, Pagination, PaginationItem, PaginationLink, CardBody } from 'reactstrap';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

const priorityMap = {
  2: { text: 'High' },
  1: { text: 'Medium' },
  0: { text: 'Low' },
  default: { text: 'Unknown' },
};

const tableHeaderStyle = {
  fontSize: '14px',
  textTransform: 'capitalize',
  fontWeight: 'normal',
};

const tableRowStyle = {
  fontSize: '14px',
  padding: '15px',
};

const ReportsTable = ({ reports = [] }) => {
  const [sortedReports, setSortedReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [reportsPerPage] = useState(10); 
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  useEffect(() => {
    setSortedReports(Array.isArray(reports) ? reports : []);
  }, [reports]);

  const sortReports = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedData = [...sortedReports].sort((a, b) => {
      if (key === 'timeReported') {
        const dateA = a[key]?.seconds ? new Date(a[key].seconds * 1000) : new Date();
        const dateB = b[key]?.seconds ? new Date(b[key].seconds * 1000) : new Date();
        return direction === 'ascending' ? dateA - dateB : dateB - dateA;
      }

      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortedReports(sortedData);
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    return sortConfig.key === key && sortConfig.direction === 'ascending'
      ? <FaArrowUp />
      : <FaArrowDown />;
  };

  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = sortedReports.slice(indexOfFirstReport, indexOfLastReport);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(sortedReports.length / reportsPerPage);

  const generatePagination = () => {
    const paginationItems = [];
    paginationItems.push(
      <PaginationItem disabled={currentPage === 1} key="prev">
        <PaginationLink
          onClick={() => paginate(currentPage - 1)}
          style={{ textTransform: 'uppercase', fontSize: '12px', color: 'lightgrey' }}
        >
          Previous
        </PaginationLink>
      </PaginationItem>
    );

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
        paginationItems.push(
          <PaginationItem key={i} active={i === currentPage}>
            <PaginationLink onClick={() => paginate(i)}>{i}</PaginationLink>
          </PaginationItem>
        );
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        paginationItems.push(
          <PaginationItem disabled key={`ellipsis-${i}`}>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        );
      }
    }

    paginationItems.push(
      <PaginationItem disabled={currentPage === totalPages} key="next">
        <PaginationLink
          onClick={() => paginate(currentPage + 1)}
          style={{ textTransform: 'uppercase', fontSize: '12px', color: 'lightgrey' }}
        >
          Next
        </PaginationLink>
      </PaginationItem>
    );

    return paginationItems;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); 
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const getCombinedHazardType = (hazardType, bombType) => {
    return [hazardType, bombType].filter(Boolean).join(' / ');
  };

  return (
    <CardBody>
      <div style={{ overflowX: 'auto' }}> 
        <Table hover responsive>
          <thead>
            <tr>
              <th style={tableHeaderStyle}></th>
              <th style={tableHeaderStyle}>Read/Unread</th>
              <th style={tableHeaderStyle} onClick={() => sortReports('reportType')}>
                Type {getSortIcon('reportType')}
              </th>
              <th style={tableHeaderStyle}>Hazard Type</th>
              <th style={tableHeaderStyle} onClick={() => sortReports('location')}>
                Location {getSortIcon('location')}
              </th>
              <th style={tableHeaderStyle} onClick={() => sortReports('timeReported')}>
                Time {getSortIcon('timeReported')}
              </th>
              <th style={tableHeaderStyle} onClick={() => sortReports('reportingApp')}>
                Reporting App {getSortIcon('reportingApp')}
              </th>
              <th style={tableHeaderStyle} onClick={() => sortReports('verifiedStatus')}>
                Verified Status {getSortIcon('verifiedStatus')}
              </th>
              <th style={tableHeaderStyle} onClick={() => sortReports('priority')}>
                Priority {getSortIcon('priority')}
              </th>
              <th style={tableHeaderStyle}>Related Investigation</th>
            </tr>
          </thead>
          <tbody>
            {currentReports.map((report, index) => (
              <tr key={index}>
                <td style={tableRowStyle}>
                  <a href={`/admin/report/view/${report.documentId}`} target="_blank" rel="noopener noreferrer">
                    VIEW
                  </a>
                </td>
                <td style={tableRowStyle}>In development</td>
                <td style={tableRowStyle}>{report.reportType}</td>
                <td style={tableRowStyle}>
                  {getCombinedHazardType(report.hazardType, report.bombType)}
                </td>
                <td style={tableRowStyle}>{`${report.city || 'Unknown City'}, ${report.country || 'Unknown Country'}`}</td>
                <td style={tableRowStyle}>
                  {report.timeReported?.seconds
                    ? formatDate(report.timeReported.seconds)
                    : 'N/A'}
                </td>
                <td style={tableRowStyle}>{report.reportingApp}</td>
                <td style={tableRowStyle}>{report.verifiedStatus}</td>
                <td style={tableRowStyle}>
                  {priorityMap[report.priority]?.text || priorityMap['default'].text}
                </td>
                <td style={tableRowStyle}>In development</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination>{generatePagination()}</Pagination>
    </CardBody>
  );
};

export default ReportsTable;
