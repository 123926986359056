import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

function PhotoViewer({ photoUrls }) {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [modal, setModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [validPhotoUrls, setValidPhotoUrls] = useState(photoUrls);
    const handlePrev = () => {
        setPhotoIndex(Math.max(photoIndex - 2, 0));
    };

    const handleNext = () => {
        setPhotoIndex(Math.min(photoIndex + 2, validPhotoUrls.length - 2));
    };

    const toggleModal = () => setModal(!modal);

    const showImageInModal = (url) => {
        setSelectedImage(url);
        toggleModal();
    };

    const handleImageError = (url) => {
        setValidPhotoUrls(prevUrls => prevUrls.filter(item => item !== url));
    };

    const totalPages = Math.ceil(validPhotoUrls.length / 2);
    const currentPage = Math.ceil(photoIndex / 2 + 1);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: '10px' }}>
                {validPhotoUrls.slice(photoIndex, photoIndex + 2).map((url, index) => (
                    <img
                        key={index}
                        src={url}
                        alt="Report"
                        style={{ width: '190px', height: '130px', objectFit: 'cover', marginRight: index === 0 ? '10px' : '0' }}
                        onClick={() => showImageInModal(url)}
                        onError={() => handleImageError(url)} 
                    />
                ))}
            </div>
            <div style={{ display: 'flex', marginLeft: '170px', alignItems: 'center' }}>
                <button style={{ background: 'transparent', border: 'none' }} onClick={handlePrev} disabled={photoIndex === 0}>
                    <i className="nc-icon nc-minimal-left" style={{ fontSize: '12px', color: 'black' }}></i>
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                    <span key={i} style={{
                        padding: '5px 10px',
                        margin: '0 5px',
                        backgroundColor: currentPage === i + 1 ? '#d3d3d3' : 'transparent',
                        color: currentPage === i + 1 ? 'white' : 'black',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }} onClick={() => setPhotoIndex(i * 2)}>
                        {i + 1}
                    </span>
                ))}
                <button style={{ background: 'transparent', border: 'none' }} onClick={handleNext} disabled={photoIndex >= validPhotoUrls.length - 2}>
                    <i className="nc-icon nc-minimal-right" style={{ fontSize: '12px', color: 'black' }}></i>
                </button>
            </div>
            <Modal isOpen={modal} toggle={toggleModal} size="lg">
                <ModalBody>
                    <img src={selectedImage} alt="Enlarged" style={{ width: '100%', height: 'auto' }} />
                </ModalBody>
            </Modal>
        </div>
    );
}

export default PhotoViewer;
