import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Card, CardHeader, CardBody, CardFooter, Row, Col, Badge } from 'reactstrap';

const LineChart = ({ data, title, subtitle, footerContent, badgeContent, colorIndex = 0 }) => {
  const chartRef = useRef(null);
  const colors = ['#6BD098', '#EF8157', '#FBC658'];
  const chartData = {
    ...data,
    datasets: data.datasets.map((dataset, index) => ({
      ...dataset,
      borderColor: colors[(index + colorIndex) % colors.length],
      backgroundColor: colors[(index + colorIndex) % colors.length], 
     })),
  };

  const chartExample1 = {
    options: {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true, 
          callbacks: {
            label: function(context) {
              const label = context.dataset.label || '';
              const value = context.raw || '';
              return `${label}: ${value}`; 
            },
          },
        },
      },
      elements: {
        line: {
          tension: 0.5, 
          borderCapStyle: 'round',
        },
        point: {
          radius: 0.5,
          hoverRadius: 6, 
        },
      },
      scales: {
        y: {
          ticks: {
            color: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5,
          },
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            padding: 20,
            color: "#9f9f9f",
          },
        },
      },
    },
  };

  return (
    <Col lg="4" sm="6">
      <Card>
        <CardHeader>
          <Row>
            <Col sm="7">
              <div className="numbers pull-left">{title}</div>
            </Col>
            <Col sm="5">
              <div className="pull-right">
                {badgeContent && (
                  <Badge color="success" pill>
                    {badgeContent}
                  </Badge>
                )}
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody style={{ position: 'relative' }}>
          <h6 className="big-title">{subtitle}</h6>
          <Line ref={chartRef} data={chartData} options={chartExample1.options} height={380} width={820} />
        </CardBody>
        <CardFooter>
          <hr />
          <Row>
            <Col sm="7">
              <div className="footer-title" style={{ fontSize: '10px' }}>{footerContent}</div>
            </Col>
            <Col sm="5">
              <div className="pull-right">
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            {/* Circle icon with the line color */}
            <div style={{
              position: 'absolute',
              bottom: '10px',
              right: '10px',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: chartData.datasets[0].borderColor,
            }}></div>
          </Row>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default LineChart;
