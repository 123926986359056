import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import routes from 'routes.js';

const TitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    const currentRoute = routes.find(route => {
      const path = route.layout + route.path;
      return path === location.pathname;
    });

    if (currentRoute) {
      document.title = currentRoute.name;
    } else {
      document.title = 'SleuthNet Pro Dashboard';
    }
  }, [location]);

  return null;
};

export default TitleUpdater;
