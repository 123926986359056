import React from 'react';
import csvIcon from '../../assets/img/csv.png';

const DownloadButtonBasicReport = ({ data, fileName = true }) => {

    const downloadCsv = () => {
        if (!data || data.length === 0) {
            console.error('No data available for CSV download');
            return;
        }

        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(';')); 

        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(';')); 
        }

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}.csv`;  
        link.click();
        URL.revokeObjectURL(url);
    };

    return (
        <div className="download-icons-container">
            <div style={{ marginRight: '20px', cursor: 'pointer' }} onClick={downloadCsv}>
                <img src={csvIcon} alt="Download CSV" width="24" height="24" />
                <span style={{ marginLeft: '5px' }}>CSV</span>
            </div>
        </div>
    );
};

export default DownloadButtonBasicReport;
