import React, { useState, useRef } from 'react';

const VideoPlayer = ({ videoUrl }) => {
  const videoRefs = useRef(videoUrl.map(() => React.createRef()));
  const [videoIndex, setVideoIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(Array(videoUrl.length).fill(false));

  const togglePlay = (index) => {
    if (videoRefs.current[index].current) {
      if (isPlaying[index]) {
        videoRefs.current[index].current.pause();
      } else {
        videoRefs.current[index].current.play();
      }
      setIsPlaying(isPlaying.map((play, idx) => idx === index ? !play : play));
    }
  };

  const enterFullScreen = (index) => {
    if (videoRefs.current[index].current.requestFullscreen) {
      videoRefs.current[index].current.requestFullscreen();
    } else if (videoRefs.current[index].current.webkitRequestFullscreen) { // Safari, Chrome
      videoRefs.current[index].current.webkitRequestFullscreen();
    } else if (videoRefs.current[index].current.mozRequestFullScreen) { // Firefox
      videoRefs.current[index].current.mozRequestFullScreen();
    } else if (videoRefs.current[index].current.msRequestFullscreen) { // IE, Edge
      videoRefs.current[index].current.msRequestFullscreen();
    }
  };

  const handlePrev = () => {
    setVideoIndex(Math.max(videoIndex - 2, 0));
  };

  const handleNext = () => {
    setVideoIndex(Math.min(videoIndex + 2, videoUrl.length - 2));
  };

  const totalPages = Math.ceil(videoUrl.length / 2);
  const currentPage = Math.ceil(videoIndex / 2 + 1);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: '10px' }}>
        {videoUrl.slice(videoIndex, videoIndex + 2).map((url, index) => (
          <div key={index} style={{ width: '190px', height: '130px', position: 'relative', marginRight: index === 0 ? '10px' : '0' }}>
            <video
              ref={videoRefs.current[videoIndex + index]}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              onEnded={() => {
                const newIsPlaying = [...isPlaying];
                newIsPlaying[videoIndex + index] = false;
                setIsPlaying(newIsPlaying);
              }}
            >
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {!isPlaying[videoIndex + index] && (
              <button
                onClick={() => togglePlay(videoIndex + index)}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '80px',
                  height: '80px',
                  backgroundColor: 'transparent',
                  borderRadius: '50%',
                  border: '6px solid rgba(255, 255, 255, 0.7)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  outline: 'none',
                  zIndex: 2
                }}
              >
                <svg width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="rgba(255, 255, 255, 0.8)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
                  <polygon fill="rgba(255, 255, 255, 0.3)" points="6 4 20 12 6 20 6 4"></polygon>
                </svg>
              </button>
            )}
            <button
              onClick={() => enterFullScreen(videoIndex + index)}
              style={{
                position: 'absolute',
                bottom: '1px',
                right: '1px',
                color:  'rgba(255, 255, 255, 0.8)',
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                padding: '5px 10px',
                borderRadius: '5px',
                zIndex: 2
              }}
            >
              <svg width="20" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinejoin="round">
                <path d="M8 3H5a2 2 0 0 0-2 2v3m14 0V5a2 2 0 0 0-2-2h-3m0 14h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
              </svg>
            </button>
          </div>
        ))}
      </div>   
      <div style={{ display: 'flex',marginLeft:'170px', alignItems: 'center' }}> 
      <button style={{ background: 'transparent', border: 'none' }} onClick={handlePrev} disabled={videoIndex === 0}>
          <i className="nc-icon nc-minimal-left" style={{ fontSize: '12px', color: 'black' }}></i>
        </button>
        {Array.from({ length: totalPages }, (_, i) => (
          <span key={i} style={{
            padding: '5px 10px',
            margin: '0 5px',
            backgroundColor: currentPage === i + 1 ? '#d3d3d3' : 'transparent',
            color: currentPage === i + 1 ? 'white' : 'black',
            borderRadius: '5px',
            cursor: 'pointer'
          }} onClick={() => setVideoIndex(i * 2)}>
            {i + 1}
          </span>
        ))}
        <button style={{ background: 'transparent', border: 'none' }} onClick={handleNext} disabled={videoIndex >= videoUrl.length - 2}>
          <i className="nc-icon nc-minimal-right" style={{ fontSize: '12px', color: 'black' }}></i>
        </button>
      </div>
    </div>
  );
};

export default VideoPlayer;
