import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Card, CardBody, CardHeader, Container, Row, Col } from 'reactstrap';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

const PasswordResetRequestForm = ({ onReturnToLogin }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const auth = getAuth();

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage('Check your email inbox for the password reset link.');
        setError('');
      })
      .catch((error) => {
        console.error("Error sending password reset email:", error);
        setMessage('');
        setError('Failed to send password reset email. Please make sure the email is correct.');
      });
  };

  return (
    <Container>
      <Row>
        <Col sm="12" md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}> 
          <Card className="mt-5">
            <CardHeader className="text-center">
              <h4>Reset Your Password</h4>
            </CardHeader>
              <CardBody>
                  <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="userEmail">Email Address</Label>
                    <Input
                      type="email"
                      name="email"
                      id="userEmail"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <div className="text-center" style={{ marginBottom: '10px' }}> 
                    <Button type="submit" color="primary">Send Password Reset Email</Button>
                  </div>
                  {message && <div className="text-success text-center" style={{ marginTop: '10px', marginBottom: '10px' }}>{message}</div>}
                  {error && <div className="text-danger text-center" style={{ marginTop: '10px', marginBottom: '10px' }}>{error}</div>}
                  <div className="text-center" style={{ marginTop: '10px' }}>
                    <Button color="link" onClick={onReturnToLogin}>
                      Return to Login
                    </Button>
                  </div>
                </Form>
              </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordResetRequestForm;
