import React, { useEffect, useState } from 'react';
import { collection, query, getDocs, where, Timestamp } from 'firebase/firestore';
import TotalCountCardGeneral from './components/TotalCountCardGeneral';
import LineChart from '../variables/LineChart';
import ExploreMapMain from './maps/ExploreMapMain';  
import { Row, Col, Spinner } from 'reactstrap';
import { firestore } from '../firebase';

const MainDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [reports, setReports] = useState([]);
  const [reportCountsForCards, setReportCountsForCards] = useState({});
  const [totalReportsTotalCount, setTotalReportsTotalCount] = useState(0);
  const [totalContributors, setTotalContributors] = useState(0);
  const [totalHazards, setTotalHazards] = useState(0);
  const [totalCountries, setTotalCountries] = useState(0);
  const [dataForTotalReports, setDataForTotalReports] = useState({ labels: [], datasets: [] });
  const [dataForUkraineWarTrend, setDataForUkraineWarTrend] = useState({ labels: [], datasets: [] });
  const [dataForIsraelPalestineWarTrend, setDataForIsraelPalestineWarTrend] = useState({ labels: [], datasets: [] });
  const [latestReportTime, setLatestReportTime] = useState(null);

  const processDataForCharts = (data, label, setData, color) => {
    const labels = Object.keys(data).map(key => {
      const [year, month] = key.split('-');
      return `${monthNames[parseInt(month)]} ${year}`;
    });
    const values = Object.values(data);
    setData({
      labels,
      datasets: [{
        label,
        data: values,
        borderColor: color,
        fill: false,
      }],
    });
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const today = new Date();
    const pastYear = new Date();
    pastYear.setMonth(today.getMonth() - 12);
    const startDate = Timestamp.fromDate(pastYear);
    const endDate = Timestamp.fromDate(today);

    const fetchData = async () => {
      try {
        const q = query(collection(firestore, 'Reports'), where("timeReported", ">=", startDate), where("timeReported", "<=", endDate));
        const querySnapshot = await getDocs(q);

        if (!isMounted) return;

        const fetchedReports = [];
        const reportCountsForCards = { ukraineRussia: 0, israelPalestine: 0, violentExtremism: 0, climateChange: 0, federalElections: 0 };
        const totalReportsByMonth = {};
        const ukraineWarTrendByDay = {};
        const israelPalestineWarTrendByMonth = {};
        let totalHazardsSet = new Set(); 
        let totalCountriesSet = new Set(); 
        let totalReportsCount = 0;
        let latestTime = null; 

        querySnapshot.forEach((doc) => {
          const report = doc.data();
          const { reportType, country, timeReported, latitude, longitude, hazardType, bombType } = report;
          const reportDate = timeReported.toDate();
          const month = reportDate.getMonth();
          const year = reportDate.getFullYear();
          const day = reportDate.getDate();
          const monthKey = `${year}-${month}`;
          const dayKey = `${year}-${month}-${day}`;

          if (latitude && longitude && reportType) {
            fetchedReports.push({ ...report });
          }
          if (country === 'Ukraine' || country === 'Russia') reportCountsForCards.ukraineRussia += 1;
          if (country === 'Israel' || country === 'Palestine') reportCountsForCards.israelPalestine += 1;
          if (reportType === 'terroristActivity') reportCountsForCards.violentExtremism += 1;
          totalReportsCount++;
          if (hazardType) totalHazardsSet.add(hazardType);
          if (bombType) totalHazardsSet.add(bombType);
          totalCountriesSet.add(country);
          totalReportsByMonth[monthKey] = (totalReportsByMonth[monthKey] || 0) + 1;
          if (country === 'Ukraine' || country === 'Russia') {
            ukraineWarTrendByDay[dayKey] = (ukraineWarTrendByDay[dayKey] || 0) + 1;
          }
          if (country === 'Israel' || country === 'Palestine') {
            israelPalestineWarTrendByMonth[monthKey] = (israelPalestineWarTrendByMonth[monthKey] || 0) + 1;
          }
          if (!latestTime || report.timeReported.seconds > latestTime.seconds) {
            latestTime = report.timeReported;
          }
        });

        setReports(fetchedReports);
        setReportCountsForCards(reportCountsForCards);
        setTotalReportsTotalCount(totalReportsCount);
        setTotalHazards(totalHazardsSet.size);
        setTotalCountries(totalCountriesSet.size);

        const contributorsSnapshot = await getDocs(collection(firestore, 'users'));
        setTotalContributors(contributorsSnapshot.size);

        processDataForCharts(totalReportsByMonth, 'Total Reports', setDataForTotalReports, 'rgb(75, 192, 192)');
        processDataForCharts(ukraineWarTrendByDay, 'Ukraine/Russia War Reports', setDataForUkraineWarTrend, 'rgb(255, 99, 132)');
        processDataForCharts(israelPalestineWarTrendByMonth, 'Israel/Palestine War Reports', setDataForIsraelPalestineWarTrend, 'rgb(54, 162, 235)');
        setLatestReportTime(latestTime ? new Date(latestTime.seconds * 1000).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }) : 'N/A');
        
        setIsLoading(false);

      } catch (error) {
        console.error("Error loading dashboard data:", error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner />
        <p style={{ marginTop: '20px', fontSize: '18px', fontWeight: '500' }}>
          Please wait, we are fetching the data for you...
        </p>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', marginTop: '80px', flexWrap: 'wrap', width: '100%', alignItems: 'flex-start' }}>
    {/* Row 1: Total Count Cards */}
    <div className="first-row-cards" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <TotalCountCardGeneral title="Ukraine/Russia" count={reportCountsForCards.ukraineRussia} subtitle="View Report" />
        <TotalCountCardGeneral title="Israel/Palestine" count={reportCountsForCards.israelPalestine} subtitle="View Report" />
        <TotalCountCardGeneral title="Violent Extremism" count={reportCountsForCards.violentExtremism} subtitle="View Report" />
        <TotalCountCardGeneral title="Climate Change" count={reportCountsForCards.climateChange} subtitle="View Report" />
        <TotalCountCardGeneral title="Federal Elections" count={0} subtitle="View Report" />
      </div>

      {/* Second row of cards */}
      <div className="second-row-cards" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
        <TotalCountCardGeneral title="Total Reports" count={totalReportsTotalCount} subtitle="Last update: " latestUpdate={latestReportTime} wide /> 
        <TotalCountCardGeneral title="Total Contributors" count={totalContributors} subtitle="Current Contributors" wide />
        <TotalCountCardGeneral title="Total Unique Hazards" count={totalHazards} subtitle="Last update: " latestUpdate={latestReportTime} wide />
        <TotalCountCardGeneral title="Total Countries" count={totalCountries} subtitle="Current Countries" wide />
      </div>

  
      {/* Line Charts */}
      <Row style={{ marginTop: '20px', marginLeft: '2px', width: '100%', marginRight: '25px', }}>
        {dataForTotalReports.datasets.length > 0 &&
          <LineChart title={totalReportsTotalCount}  subtitle="Total Reports (Past Year)" data={dataForTotalReports} colorIndex={0} />
        }
        {dataForUkraineWarTrend.datasets.length > 0 &&
          <LineChart title={reportCountsForCards.ukraineRussia} subtitle="Ukraine War Reporting Trend" data={dataForUkraineWarTrend} colorIndex={1} />
        }
        {dataForIsraelPalestineWarTrend.datasets.length > 0 &&
          <LineChart title={reportCountsForCards.israelPalestine} subtitle="Israel/Palestine Reporting Trend" data={dataForIsraelPalestineWarTrend} colorIndex={2} />
        }
      </Row>

      {/* Explore Map Component */}
      <div className="content" style={{ width: '100%', marginLeft: '15px', marginRight: '18px', height: '100%' }}>
        <Row style={{ marginTop: '20px', marginLeft: '2px', width: '100%', marginRight: '25px', }}>
          <Col md="12">
            <ExploreMapMain reports={reports} />  
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MainDashboard;
