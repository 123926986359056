import React, { useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import html2canvas from 'html2canvas';
import csvIcon from '../../assets/img/csv.png';
import pdfIcon from '../../assets/img/pdf.png';

const DownloadButton = ({ targetId, data, fileName, isDataLoaded = true }) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const downloadPdf = async () => {
        setIsDownloading(true);
        try {
            const input = document.getElementById(targetId);
            if (!input) {
                console.error(`No element found with id ${targetId}`);
                alert(`Unable to find the content to download. Please try again.`);
                setIsDownloading(false);
                return;
            }
            await new Promise(resolve => setTimeout(resolve, 500)); 
            const canvas = await html2canvas(input, {
                useCORS: true, 
                scale: 2,
                allowTaint: true,
            });

            const imgData = canvas.toDataURL('image/png');
            const pdfDoc = await PDFDocument.create();
            const page = pdfDoc.addPage([canvas.width, canvas.height]);
            const img = await pdfDoc.embedPng(imgData);
            page.drawImage(img, {
                x: 0,
                y: 0,
                width: canvas.width,
                height: canvas.height,
            });

            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${fileName}.pdf`; 
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {
            console.error('Error generating PDF', err);
        } finally {
            setIsDownloading(false);
        }
    };

    const downloadCsv = () => {
        if (!data || data.length === 0) {
            console.error('No data available for CSV download');
            return;
        }

        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(';')); 
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(';')); 
        }

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}.csv`;  
        link.click();
        URL.revokeObjectURL(url);
    };

    return (
        <div className="download-icons-container">
            <div style={{ cursor: 'pointer' }} onClick={downloadPdf}>
                <img src={pdfIcon} alt="Download PDF" width="24" height="24" />
                <span style={{ marginLeft: '5px' }}>PDF</span>
            </div>
            <div style={{ marginRight: '20px', cursor: 'pointer' }} onClick={downloadCsv}>
                <img src={csvIcon} alt="Download CSV" width="24" height="24" />
                <span style={{ marginLeft: '5px' }}>CSV</span>
            </div>
        </div>
    );
};

export default DownloadButton;
