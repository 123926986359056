export const hazardTypeMapping = {
  /* UXO */
  'antiPersonnelMine' : 'Anti-Personnel Mine', 
  'antiTankMine' : 'Anti-Tank Mine',
  'bomb' : 'Bomb', 
  'boobyTrap' : 'Booby Trap', 
  'clusterMunition' : 'Cluster Munition', 
  'droppedDispenser' : 'Dropped Dispenser',
  'droppedSubmunition' : 'Dropped Submunition',
  'explosiveRemnantsOfWar' : 'Explosive Remnants of War (ERW)',
  'generalPurposeBomb' : 'General Purpose Bomb',
  'generalPurposeChemicalBomb' : 'General Purpose Chemical Bomb',
  'grenade' : 'Grenade',
  'ied' : 'Improvised Explosive Device (IED)',
  'landmine' : 'Landmine',
  'missile' : 'Missile',
  'mortar' : 'Mortar',
  'projectile' : 'Projectile',
  'rifleGrenade' : 'Rifle Grenade',
  'rocket' : 'Rocket', 
  /* War Crime */
  'killingOfCivilians' : 'Killing of Civilians',
  'torture' : 'Torture', 
  'sexualAssaultWar' : 'Sexual Assault', 
  'forcedDeportation' : 'Forced Deportation',
  'attackOnCivilians' : 'Attack on Civilians', 
  'attackProtectedSite' : 'Attack on Protected Site', 
  'childSoldiers' : 'Child Soldiers',
  'starvationWarfare' : 'Starvation as Warfare', 
  'humanShield' : 'Human Shield',
  'attackMedicalPersonnel' : 'Attack on Medical Personnel',
  'attackMediaPersonnel' : 'Attack on Media Personnel', 
  'chemicalWarfareWar' : 'Chemical Warfare Agents', 
  'denialFairTrial' : 'Denial of Fair Trial', 
  'lootingCivilianProperty' : 'Looting Civilian Property', 
  'indiscriminateAttack' : 'Indiscriminate Attack', 
  'prohibitedWeapons' : 'Use of Prohibited Weapons', 
  'forcedLabor' : 'Forced Labor', 
  'attackCulturalSites' : 'Attack on Cultural Heritage Sites',
  /* Criminal Activity */
  'riot' : 'Riot',
  'murder' : 'Homicide', 
  'rape' : 'Sexual Assault (Criminal Activity)', 
  'kidnapping' : 'Kidnapping',
  'arson' : 'Arson', 
  'robbery' : 'Robbery',
  'shooting' : 'Shooting', 
  'bombing' : 'Bombing', 
  'assault' : 'Assault', 
  'hate' : 'Hate Crime', 
  'carJacking' : 'Carjacking', 
  'home' : 'Home Invasion', 
  'gangViolence' : 'Gang Violence', 
  'humanTrafficking' : 'Human Trafficking',
  'looting' : 'Looting', 
  'cybercrime' : 'Cybercrime', 
  'drugTrafficking' : 'Drug Trafficking', 
  'fraud' : 'Fraud',
  'extortion' : 'Extortion', 
  /* Structure Hazard */
  'structuralDamage' : 'Structural Damage',
  'structureFire' : 'Structure Fire', 
  'downPowerLine' : 'Down Power Line',
  'waterHazard' : 'Water Hazard', 
  'roadWashout' : 'Road Washout', 
  'gasLeak' : 'Gas Leak', 
  'collapse' : 'Structure Collapse', 
  'sinkhole' : 'Sinkhole',
  'structuralInstability' : 'Structural Instability', 
  /* Natural Disaster */
  'flood' : 'Flood',
  'wildFire' : 'Wildfire',
  'hurricane' : 'Hurricane', 
  'tornado' : 'Tornado',
  'landSlide' : 'Landslide',
  'earthquake' : 'Earthquake', 
  'tsunami' : 'Tsunami',
  'volcanoes' : 'Volcanic Eruption', 
  'drought' : 'Drought',
  'avalanche' : 'Avalanche',
  'extremeHeat' : 'Heatwave',
  'extremeCold' : 'Cold Wave',
  'blizzard' : 'Blizzard', 
  'hailStorm' : 'Hailstorm', 
  'sinkHole' : 'Sinkhole',
  'sandStorm' : 'Sandstorm',
  'severeStorm' : 'Severe Storm',
  'solarFlare' : 'Solar Flare',
  'meteorStrike' : 'Meteor Strike',  
  /* Wildlife */
  'alligatorCrocodile' : 'Alligator/Crocodile',
  'bear' : 'Bear', 
  'bees' : 'Bees', 
  'boar' : 'Boar', 
  'buffalo' : 'Buffalo', 
  'dangerousCat' : 'Dangerous Cat', 
  'deerElkAntelope' : 'Deer/Elk/Antelope', 
  'elephant' : 'Elephant', 
  'hippopotamus' : 'Hippopotamus', 
  'moose' : 'Moose', 
  'shark' : 'Shark', 
  'wasps' : 'Wasps/Hornets',
  'venomousSnakes' : 'Venomous Snakes', 
  'dangerousInsects' : 'Dangerous Insects',
  /* Terrorist Activity */
  'attacksOnCivilians' : 'Attacks on Civilians',
  'attacksOnInfrastructure' : 'Attacks on Infrastructure',
  'hostageTakingKidnapping' : 'Hostage-taking and Kidnapping', 
  'bombingTerrorist' : 'Bombings and Explosions', 
  'assassination' : 'Assassinations and Targeted Killings',
  'aircraftHijacking' : 'Hijackings and Aircraft Attacks', 
  'cyberAttack' : 'Cyberattacks and Electronic Warfare',
  'intimidation' : 'Intimidation and Harassment', 
  'recruitment' : 'Recruitment and Radicalization',
  'financing' : 'Financing and Fundraising', 
  'propaganda' : 'Propaganda and Dissemination of Ideology', 
  'checkpointsTerrorist' : 'Illegal Checkpoint', 
  'nightLetters' : 'Night Letters',
  'useOfCBRNWeapons' : 'Use of CBRN Weapons', 
  'insiderThreats' : 'Insider Threats', 
  /* Military Activity */
 
  'peacekeepingMissions' : 'Peacekeeping Missions',
  'combatOperations' : 'Combat Operations', 
  'reconAndSurveillance' : 'Reconnaissance and Surveillance', 
  'militaryTrainingExercise' : 'Military Training and Exercises', 
  'hadr' : 'Humanitarian Aid and Disaster Relief (HA/DR)', 
  'securityPatrols' : 'Security Patrols',
  'counterTerrorismOperations' : 'Counterterrorism Operations', 
  'cyberWarfare' : 'Cyber Warfare', 
  'logistics' : 'Logistics and Support', 
  'eod' : 'Demining and Explosive Ordnance Disposal (EOD)',
  'psyops' : 'Psychological Operations (PsyOps)', 
  'searchAndRescue' : 'Search and Rescue Operations',
  'navalBlockade' : 'Naval Blockades', 
  'airDefenseOperations' : 'Air Defense Operations', 
  'counterinsurgencyOperations' : 'Counterinsurgency Operations', 
  'electronicWarfare' : 'Electronic Warfare',
  'intelligenceOperations' : 'Military Intelligence Operations',
  'antiPiracyOperations' : 'Anti-Piracy Operations',
  'specialForcesOperations' : 'Special Forces Operations',
  'droneStrikes' : 'Drone Strikes', 
  'droneSurveillance' : 'Drone Surveillance', 
  'ambush' : 'Ambush', 
  'sead' : 'Suppression of Enemy Air Defenses (SEAD)', 
  'militaryAdvisoryTraining' : 'Military Advisory and Training Missions', 
  'armsControlDisarmament' : 'Arms Control and Disarmament Efforts',
  'medicalServices' : 'Medical Services',
  'engineeringConstruction' : 'Engineering and Construction',
  'nuclearDeterrence' : 'Nuclear Deterrence Operations',
  'jointMultinationalOps' : 'Joint Multinational Operations', 
  'counterNarcotics' : 'Counter-Narcotics Operations',
  'maritimeSecurity' : 'Maritime Security Operations', 
  'airborneOps' : 'Airborne Operations',
  'amphibiousWarfare' : 'Amphibious Warfare Operations',
  'infantryEngagements' : 'Infantry Engagements', 
  'armoredWarfare' : 'Armored Warfare', 
  'artilleryStrikes' : 'Artillery Strikes', 
  'aerialDogfights' : 'Aerial Dogfights', 
  'navalEngagements' : 'Naval Engagements', 
  'submarineWarfare' : 'Submarine Warfare', 
  'urbanCombat' : 'Urban Combat', 
  'guerrillaWarfare' : 'Guerrilla Warfare', 
  'tunnelWarfare' : 'Tunnel Warfare', 
  'mountainWarfare' : 'Mountain Warfare', 
  'jungleWarfare' : 'Jungle Warfare',
  'desertWarfare' : 'Desert Warfare',
  'arcticWarfare' : 'Arctic Warfare',
  'siegeOperations' : 'Siege Operations',
  'precisionStrikes' : 'Precision Strikes',
  'carrierStrikeOperations' : 'Carrier Strike Operations',
  'strategicBombing' : 'Strategic Bombing',
  'antiSubmarineWarfare' : 'Anti-Submarine Warfare',
  'antiAirWarfare' : 'Anti-Air Warfare',
  'cbrnWarfare' : 'Chemical, Biological, Radiological, and Nuclear Warfare (CBRN)', 
  'informationWarfare' : 'Information Warfare', 
  'closeAirSupport' : 'Close Air Support', 
  'searchAndDestroy' : 'Search and Destroy Missions',
  'decapitationStrikes' : 'Decapitation Strikes',
  'militaryCheckpoint' : 'Checkpoint',
  'ieds' : 'Improvised Explosive Devices (IEDs)', 
  'uavOperations' : 'Unmanned Aerial Vehicle (UAV) Operations', 
  'falseSurrenders' : 'False Surrenders',
  /* Resource Scarcity */
  'fuelOutage' : 'No Fuel', 
  'powerOutage' : 'No Power',
  'cellularOutage' : 'No Cellular Service',
  'foodOutage' : 'No Food', 
  'waterOutage' : 'No Water', 
  'loadShed' : 'Load Shedding',
  'medicalSupplyShortage' : 'Medical Supply Shortage',
  'shelterShortage' : 'Shelter Shortage', 
  /* NASA  */
  'wildfires' : 'Wildfires',
  'volcanoes' : 'Volcanic Activity',
  'severeStorms' : 'Severe Storms', 
  'floods' : 'Floods', 
  'earthquakes' : 'Earthquakes', 
  'landslides' : 'Landslides', 
  'dustHaze' : 'Dust and Haze',
  'snow' : 'Snow',
  'icebergs' : 'Icebergs',
  /* Environmental Hazards */
  'hazmatSpill': 'Hazardous Material Spill', 
  'radiationLeak': 'Radiation Leak', 
  'oilSpill': 'Oil Spill', 
  /* Transportation Hazards */
  'aircraftAccident': 'Aircraft Accident', 
  'trainDerailment': 'Train Derailment',
  'maritimeAccident': 'Maritime Accident', 
  /* Cyber Threats */
  'dataBreach': 'Data Breach',
  'dosAttack': 'Denial of Service Attack', 
  'phishingScam': 'Phishing Scam',
  /* Public Health Hazards */
  'diseaseOutbreak': 'Disease Outbreak', 
  'contaminatedWater': 'Contaminated Water Supply',
  'airbornePollutants': 'Airborne Pollutants',
  /* Human Trafficking */
  'minorKidnapping': 'Kidnapping (Child/Teen)',
  'adultKidnapping': 'Kidnapping (Adult)', 
  'sexualAssaultTrafficking': 'Sexual Assault (Human Trafficking)', 
  'prostitution': 'Prostitution', 
  'forcedMarriage': 'Forced Marriage',
  'organTrafficking': 'Organ Trafficking',

  'other': 'Other',
  'Other': 'Other'
};

export const bombTypeMapping = {
  'antipersonnel_mine_1': 'Anti-Personnel Mine',
  'antipersonnel_mine_2': 'Anti-Personnel Mine',
  'antipersonnel_mine_3': 'Anti-Personnel Mine',
  'antipersonnel_mine_4': 'Anti-Personnel Mine',
  'antipersonnel_mine_5': 'Anti-Personnel Mine',
  'antipersonnel_mine_6': 'Anti-Personnel Mine',
  'antitank_mine_1': 'Anti-Tank Mine',
  'antitank_mine_2': 'Anti-Tank Mine',
  'antitank_mine_3': 'Anti-Tank Mine',
  'antitank_mine_4': 'Anti-Tank Mine',
  'dropped_dispenser_1': 'Dropped Dispenser',
  'dropped_dispenser_2': 'Dropped Dispenser',
  'dropped_dispenser_4': 'Dropped Dispenser',
  'dropped_dispenser_5': 'Dropped Dispenser',
  'dropped_dispenser_6': 'Dropped Dispenser',
  'dropped_dispenser_7': 'Dropped Dispenser',
  'dropped_submunition_1': 'Dropped Submunition',
  'dropped_submunition_2': 'Dropped Submunition',
  'dropped_submunition_3': 'Dropped Submunition',
  'dropped_submunition_4': 'Dropped Submunition',
  'dropped_submunition_5': 'Dropped Submunition',
  'dropped_submunition_6': 'Dropped Submunition',
  'general_purpose_bomb_chemical_1': 'General Purpose Bomb (Chemical)',
  'general_purpose_bomb_chemical_2': 'General Purpose Bomb (Chemical)',
  'general_purpose_bomb_1': 'General Purpose Bomb',
  'general_purpose_bomb_2': 'General Purpose Bomb',
  'general_purpose_bomb_3': 'General Purpose Bomb',
  'general_purpose_bomb_4': 'General Purpose Bomb',
  'general_purpose_bomb_5': 'General Purpose Bomb',
  'general_purpose_bomb_6': 'General Purpose Bomb',
  'general_purpose_bomb_7': 'General Purpose Bomb',
  'general_purpose_bomb_8': 'General Purpose Bomb',
  'missile_1': 'Missile',
  'missile_2': 'Missile',
  'mortar_1': 'Mortar',
  'mortar_2': 'Mortar',
  'mortar_3': 'Mortar',
  'mortar_4': 'Mortar',
  'projectile_1': 'Projectile',
  'projectile_2': 'Projectile',
  'projectile_3': 'Projectile',
  'projectile_4': 'Projectile',
  'projectile_5': 'Projectile',
  'projectile_6': 'Projectile',
  'projectile_7': 'Projectile',
  'projectile_8': 'Projectile',
  'projectile_9': 'Projectile',
  'projectile_10': 'Projectile',
  'projectile_11': 'Projectile',
  'projectile_12': 'Projectile',
  'projectile_13': 'Projectile',
  'projectile_14': 'Projectile',
  'projectile_15': 'Projectile',
  'projectile_16': 'Projectile',
  'rifle_grenade_1': 'Rifle Grenade',
  'rifle_grenade_2': 'Rifle Grenade',
  'rifle_grenade_3': 'Rifle Grenade',
  'rifle_grenade_4': 'Rifle Grenade',
  'rocket_1': 'Rocket',
  'rocket_2': 'Rocket',
  'grenade_1': 'Grenade',
  'grenade_2': 'Grenade',
  'grenade_3': 'Grenade',
  'grenade_4': 'Grenade',
  'grenade_5': 'Grenade',
  'grenade_6': 'Grenade',
  'grenade_7': 'Grenade',
  'grenade_8': 'Grenade',
  'grenade_9': 'Grenade',
  'grenade_10': 'Grenade'
};
