import React from 'react';
import { CardText } from 'reactstrap';

function DocumentDownloader({ documentUrls }) {
    if (!documentUrls || documentUrls.length === 0) {
        return <CardText>No documents attached to the report.</CardText>;
    }

    return (
        <div>
            {documentUrls.map((doc, index) => {
                if (typeof doc === 'object' && doc.url && doc.name) {
                    return (
                        <CardText key={index}>
                            <a href={doc.url} download={doc.name} target="_blank" rel="noopener noreferrer">
                                {doc.name}
                            </a>
                        </CardText>
                    );
                }
                return (
                    <CardText key={index}>
                        <a href={doc} download={`Document_${index + 1}`} target="_blank" rel="noopener noreferrer">
                            Download Document {index + 1}
                        </a>
                    </CardText>
                );
            })}
        </div>
    );
}

export default DocumentDownloader;
