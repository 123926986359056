import React from "react";
import Select, { components } from "react-select";
import { Row, Col, Badge } from "reactstrap";

class CustomizableSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.value || [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ selectedOption: this.props.value || [] });
    }
  }

  handleSelectChange = (selectedOption) => {
    if (selectedOption && selectedOption.some(option => option.value === 'select-all')) {
      const allOptions = this.props.options.filter(option => option.value !== 'select-all');
      this.setState({ selectedOption: allOptions });
      if (this.props.onChange) {
        this.props.onChange(allOptions);
      }
    } else {
      this.setState({ selectedOption });
      if (this.props.onChange) {
        this.props.onChange(selectedOption);
      }
    }
  };

  handleRemoveSelection = (valueToRemove) => {
    const updatedSelection = this.state.selectedOption.filter(
      (option) => option.value !== valueToRemove
    );
    this.setState({ selectedOption: updatedSelection });
    if (this.props.onChange) {
      this.props.onChange(updatedSelection);
    }
  };

  handleClearAll = () => {
    this.setState({ selectedOption: [] });
    if (this.props.onChange) {
      this.props.onChange([]);
    }
  };

  getFilteredAndSortedOptions = (options) => {
    return options
      .filter(option => option.value) 
      .sort((a, b) => a.label.localeCompare(b.label)); 
  };

  render() {
    const { options, placeholder, isMulti, width = '100%' } = this.props;
    const { selectedOption } = this.state;
    const extendedOptions = [
      { value: 'select-all', label: 'Select All' },
      ...this.getFilteredAndSortedOptions(options),
    ];

    const customStyles = {
      control: (styles) => ({
        ...styles,
        width: width,
        minWidth: 0,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }),
      menu: (styles) => ({
        ...styles,
        zIndex: 9999, 
        width: 'auto',
        minWidth: width,
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 9999, 
        minWidth: width,
      }),
    };
    

    const MultiValueRemove = (props) => (
      <components.MultiValueRemove {...props}>
        <i className="nc-icon nc-simple-remove" />
      </components.MultiValueRemove>
    );

    return (
      <div>
        <Row>
          <Col style={{ position: 'relative', width: width }}>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={selectedOption}
              onChange={this.handleSelectChange}
              options={extendedOptions}
              placeholder={placeholder}
              isMulti={isMulti}
              styles={customStyles}
              components={{ MultiValueRemove }}
              menuPortalTarget={document.body}
              isClearable
            />
          </Col>
        </Row>
        {selectedOption && selectedOption.length > 0 && (
          <div style={{ marginTop: '10px' }}>
            {selectedOption.map((option) => (
              <Badge
                key={option.value}
                color="primary"
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  marginRight: '5px',
                  marginBottom: '5px',
                  padding: '5px 10px',
                  cursor: 'pointer',
                }}
              >
                {option.label}
                <i
                  className="nc-icon nc-simple-remove"
                  style={{
                    marginLeft: '8px',
                    fontSize: '12px',
                  }}
                  onClick={() => this.handleRemoveSelection(option.value)}
                />
              </Badge>
            ))}
            <Badge
              color="danger"
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: '5px',
                marginBottom: '5px',
                padding: '5px 10px',
                cursor: 'pointer',
              }}
              onClick={this.handleClearAll}
              type="button"
            >
              Clear All
              <i
                className="nc-icon nc-simple-remove"
                style={{
                  marginLeft: '8px',
                  fontSize: '12px',
                }}
              />
            </Badge>
          </div>
        )}
      </div>
    );
  }
}

export default CustomizableSelect;
