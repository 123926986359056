import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Container,
  Col,
  Row,
  Form,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from '../../firebase';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import GoogleLogo from 'assets/img/sleuthnet/GoogleLogo.svg';
import NotificationAlert from "react-notification-alert";
import PasswordResetRequestForm from './ResetPassword'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordResetForm, setShowPasswordResetForm] = useState(false);
  const navigate = useNavigate();
  const notificationAlertRef = useRef(null);

  useEffect(() => {
    document.body.classList.toggle("login-page");
    return () => {
      document.body.classList.toggle("login-page");
    };
  }, []);

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const userExists = await checkIfUserExists(result.user.uid);
      if (!userExists) {
        await writeUserData(result.user, result.user.accessToken);
      }
      navigate("/admin/dashboard");
    } catch (error) {
      console.error("Error signing in with Google:", error);
      notify("Error signing in with Google. Please try again.", "danger");
    }
  };

  const checkIfUserExists = async (userId) => {
    const userDocRef = doc(firestore, "users", userId);
    const userDoc = await getDoc(userDocRef);
    return userDoc.exists();
  };

  const writeUserData = async (user, accessToken) => {
    const userDocRef = doc(firestore, "users", user.uid);
    await setDoc(userDocRef, {
      firstName: user.displayName,
      email: user.email,
      registerDate: new Date().getTime(),
      userId: user.uid,
      accessToken: accessToken,
    });
  };

  const notify = (message, type) => {
    const options = {
      place: "tr",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/admin/dashboard");
    } catch (error) {
      console.error("Login error:", error);
      notify("Error logging in with email/password. Please try again.", "danger");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordResetForm = () => {
    setShowPasswordResetForm(!showPasswordResetForm);
  };

  return (
    <div className="login-page">
      <NotificationAlert ref={notificationAlertRef} />
      {showPasswordResetForm ? (
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="6" md="8"> 
              <PasswordResetRequestForm onReturnToLogin={togglePasswordResetForm} />
            </Col> 
          </Row>
        </Container>
      ) : (
        <Container style={{marginTop:'120px'}}>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form onSubmit={handleSubmit}>
                <Card className="card-login">
                  <CardHeader>
                    <h4 className="header text-center">Login</h4>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Email..." type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Password" type={showPassword ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} />
                      <InputGroupText onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                      </InputGroupText>
                    </InputGroup>
                    <div className="text-center" style={{ marginTop: '15px' }}>
                      <Button color="link" onClick={togglePasswordResetForm}>Forgot password?</Button>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button block className="btn-round" style={{ backgroundColor:"#2196F3" }} type="submit">
                      Login
                    </Button>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0' }}>
                      <div style={{ flex: 1, height: '1px', backgroundColor: '#D0D0D0' }}></div>
                      <span style={{ padding: '0 10px', color: '#757575' }}>OR</span>
                      <div style={{ flex: 1, height: '1px', backgroundColor: '#D0D0D0' }}></div>
                    </div>
                    <Button block onClick={handleGoogleSignIn} style={{
                      backgroundColor: '#ffffff',
                      color: '#5F6368',
                      borderWidth: '1px',
                      boxShadow: '0 3px 4px 0 rgba(0,0,0,0.3)',
                      border: 'none',
                      borderRadius: '30px',
                      padding: '12px 16px',
                      fontSize: '14px',
                      fontWeight: '500',
                      letterSpacing: '0.25px',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}>
                      <img src={GoogleLogo} alt="Google Logo" style={{ marginRight: '10px' }} />
                      Sign in with Google
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
      <div className="full-page-background" style={{ backgroundImage: `url(${require("assets/img/sleuthnet/BackgroundMain.webp")})` }} />
    </div>
  );
}

export default Login;
