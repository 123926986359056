import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';
import ProtectedRoute from 'layouts/ProtectedRoute';
import { AuthProvider } from 'layouts/AuthContext';
import TitleUpdater from 'layouts/TitleUpdater'; 

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.1";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/css/paper-dashboard.css";
import 'react-date-range/dist/theme/default.css';

const setFavicon = (url) => {
  const link = document.createElement('link');
  const oldLink = document.getElementById('favicon');
  link.id = 'favicon';
  link.rel = 'icon';
  link.type = 'image/png';
  link.href = url;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
};

setFavicon(process.env.PUBLIC_URL + '/favicon.png');

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <TitleUpdater />  {/* Add TitleUpdater here */}
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="/admin/*" element={<ProtectedRoute element={<AdminLayout />} />} />
        <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
      </Routes>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
