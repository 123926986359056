import React, { useState, useEffect } from 'react';
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Row, Col } from 'reactstrap';
import { firestore } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'; 

function ContactUs() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');
  const [messageAnswer, setMessageAnswer] = useState('');

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (user) {
      const { displayName, email } = user;
      if (displayName) {
        const [firstNameValue, lastNameValue] = displayName.split(' ');
        setFirstName(firstNameValue || '');  
        setLastName(lastNameValue || '');   
      }
      setEmail(email || '');
    }
  }, []);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(firestore, 'contactUs'), {
        firstName,
        lastName,
        email,
        reason,
        messageAnswer,
        timestamp: new Date(),
      });
      alert('Feedback submitted successfully');
      setFirstName('');
      setLastName('');
      setEmail('');
      setReason('');
      setMessageAnswer('');
    } catch (error) {
      console.error("Error adding document: ", error);
      alert('Error submitting feedback');
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <div style={{ maxWidth: '600px', margin: '0 auto' }}>
              <Card>
                <CardHeader>
                  <h4>Contact Us</h4>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>First Name</label>
                          <Input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="Enter your first name"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Last Name</label>
                          <Input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Enter your last name"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <label>Email Address</label>
                      <Input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Reason for Contacting</label>
                      <Input
                        type="select"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        required
                      >
                        <option value="">Please select a reason</option>
                        <option value="feedback">Feedback</option>
                        <option value="issue">Report an Issue</option>
                        <option value="inquiry">General Inquiry</option>
                        <option value="other">Other</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <label>Message</label>
                      <Input
                        type="textarea"
                        value={messageAnswer}
                        onChange={(e) => setMessageAnswer(e.target.value)}
                        placeholder="Your message"
                        required
                      />
                    </FormGroup>
                    <Button type="submit" className="btn-fill" color="info">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ContactUs;
