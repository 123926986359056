import React from "react";
import classnames from "classnames";
import Logo from "assets/img/sleuthnet/Logo.png";
import {
  NavbarBrand,
  Navbar,
  Container,
} from "reactstrap";


function AuthNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  const toggleCollapse = () => {
    if (!collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
    setCollapseOpen(!collapseOpen);
  };
  return (
    <Navbar
      className={classnames("navbar-absolute fixed-top", color)}
      expand="lg"
      style={{ justifyContent: "center" }}
    >
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <div className="navbar-wrapper" style={{ textAlign: "center" }}>
          <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img src={Logo} alt="logo" style={{ marginBottom: "8px", height: "120px" }} />
            <div className="navbar-title">
              Sleuth Net Pro Dashboard
            </div>
          </NavbarBrand>
        </div>
      </Container>
    </Navbar>
  );
}

export default AuthNavbar;
